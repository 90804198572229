<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="1"></nav-header>

    <div class="app-container privacyDetection">
      <div class="box">
        <h1 class="box-h1">允許隱私設定</h1>
        <h2 class="box-h2">請按照圖片步驟進行操作</h2>
        <img class="box-img" src="@/assets/image/test/popupDetection1.jpg" alt="">
        <div class="box-button">
          <div class="button1" @click="openPopup">有問題請點我</div>
          <div class="button2" @click="next">
            NEXT
            <img src="@/assets/image/test/icon-next.png" alt="">
          </div>
        </div>
      </div>
      
      <!-- 問題彈窗 -->
      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div class="app-popup" v-show="popupShow">
          <transition
            enter-active-class="animated zoomIn"
            leave-active-class="animated zoomOut"
          >
            <div class="app-popup-box" v-if="popupShow">
              <img class="app-popup-box-img" src="@/assets/image/test/popupDetection2.png" alt="">
              <img class="app-popup-box-close" @click="popupShow = false" src="@/assets/image/test/icon-close.png" alt="">
              <h2 class="app-popup-box-h2">
                請依照以下步驟查看
              </h2>
              <h3 class="app-popup-box-h3">
                請家長或監考老師協助
              </h3>
              <h4 class="app-popup-box-h4">
                請點擊同意存取您的網路攝影機/前鏡頭/麥克風
              </h4>
              


              <div class="app-popup-box-p">
                <span>1.</span>
                <p>依序按一下右上方的「更多」圖示＞「設定」。</p>
              </div>
              <div class="app-popup-box-p">
                <span>2.</span>
                <p>依序按一下「隱私權和安全性」＞「網站設定」＞「攝影機」或「麥克風」。</p>
              </div>
              <div class="app-popup-box-p">
                <span>3.</span>
                <p>選擇要設為預設設定的選項。</p>
              </div>
              <div class="app-popup-box-p">
                <span></span>
                <div class="pStyle">
                  <strong>·</strong>
                  <p>查看你封鎖的網站和允許的網站。</p>
                </div>
              </div>
              <div class="app-popup-box-p">
                <span></span>
                <div class="pStyle">
                  <strong>·</strong>
                  <p>如要移除現有的例外網站或權限：按一下網站右側的「刪除」圖示。</p>
                </div>
              </div>
              <div class="app-popup-box-p">
                <span></span>
                <div class="pStyle">
                  <strong>·</strong>
                  <p>如要解除封鎖網站：在「不允許」下方選取網站名稱，然後將攝影機或麥克風權限改成「允許」。</p>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </transition>
    </div>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: 'PrivacyDetection',
  data() {
    return {
      popupShow: false,
    }
  },
  created() {
    this.getAudio()
  },
  methods: {
    // 打開問題彈窗
    openPopup() {
      this.popupShow = true
    },
    // 下一步
    next() {
      this.$router.push({
        path: '/confirmDetection'
      })
    },
    // 調用隱私權限 攝像頭、錄音
    getAudio() {
      if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
        this.getUserMedia({audio: true, video: true }); // 调用用户媒体设备，访问摄像头、录音、
      } else {
        console.log("你的浏览器不支持访问用户媒体设备");
      }
    },
    getUserMedia(constrains) {
      let that = this;
      if (navigator.mediaDevices.getUserMedia) {
        // 最新标准API、
        navigator.mediaDevices.getUserMedia(constrains).then(stream => { that.success(stream); }).catch(err => { that.error(err); });
      } else if (navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
        // webkit内核浏览器
        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {};
        }

        // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
        // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function(constraints) {
            // 首先，如果有getUserMedia的话，就获得它
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
            if (!getUserMedia) {
              return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }

            // 否则，为老的navigator.getUserMedia方法包裹一个Promise
            return new Promise(function(resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          }
        }
        navigator.mediaDevices.getUserMedia(constrains).then(stream => {
          that.success(stream);
        }).catch(err => {
          that.error(err);
        });
      } else if (navigator.getUserMedia) {
        // 旧版API
        navigator.getUserMedia(constrains).then(stream => { that.success(stream); }).catch(err => { that.error(err); });
      }
    },
    // 成功的回调函数
    success(stream) {
      console.log("已点击允许,开启成功");
    },
    // 异常的回调函数
    error(error) {
      console.log("访问用户媒体设备失败：", error.name, error.message);
    }
  }
}
</script>

<style lang="less" scoped>
.privacyDetection {
  display: flex;
  align-items: center;
  .box {
    margin: 20px auto;
    padding: 40px 0;
    width: 1320px;
    min-height: calc(100vh - 240px);
    background: #FFFFFF;
    border-radius: 8px;
    text-align: center;
    .box-h1 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 53px;
      color: #1D1B19;
    }
    .box-h2 {
      margin: 12px 0 30px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 37px;
      color: #939393;
    }
    .box-img {
      width: 836px;
      height: 410px;
    }
    .box-button {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .button1 {
        cursor: pointer;
        margin: 0 15px;
        width: 200px;
        height: 58px;
        border: 1px solid #0C4FA2;
        border-radius: 8px;
        background: #FFFFFF;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #0C4FA2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .button2 {
        cursor: pointer;
        margin: 0 15px;
        width: 200px;
        height: 58px;
        border: 1px solid #0C4FA2;
        border-radius: 8px;
        background: #0C4FA2;
        box-shadow: 0px 4px 8px rgba(0, 73, 207, 0.3);
        border-radius: 8px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-left: 6px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .app-popup-box {
    padding: 40px;
    width: 620px;
    height: 580px;
    background: #FFFFFF;
    border-radius: 10px;
    background-image: url('../../assets/image/test/popupDetection3.png');
    background-repeat: no-repeat;
    background-size: auto;
    position: relative;
    .app-popup-box-img {
      position: absolute;
      top: 0;
      right: 60px;
      width: 159px;
      height: 159px;
    }
    .app-popup-box-close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
    }
    .app-popup-box-h2 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 38px;
      line-height: 51px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
    }
    .app-popup-box-h3 {
      margin: 0 0 58px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
    }
    .app-popup-box-h4 {
      margin-bottom: 20px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 37px;
      letter-spacing: 0.05em;
      color: #000000;
    }
    .app-popup-box-p {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #555555;
      display: flex;
      justify-content: space-between;
      span {
        width: 30px;
      }
      p {
        width: calc(100% - 30px);
      }
      .pStyle {
        width: calc(100% - 40px);
        display: flex;
        align-items: flex-start;
        strong {
          width: 20px;
          height: 32px;
        }
        p {
          width: calc(100% - 20px);
        }
      }
    }
  }
}
</style>